.active-status{
    color: rgb(71, 227, 71);
    font-weight: 600;
}
.in-due-date, .in-stock{
    background-color: white;
}
.over-due-date, .out-stock{
    background-color: rgb(246, 239, 239);
}
.customer{
    background-color: white;
}
#due-table-datepicker{
    width: 100px;
    text-align: center;
}

.tickets-row{
height: 62px;
padding-left: 12px !important;
/* border: 0px, 0px, 1px, 0px; */
gap: 20px;

}

.MuiTableCell-head {
background-color: #F4F5F7;
}

.status-pill{
    /* width: 50%; */
    /* height: 10px; */
    padding: 5px !important;
    border-radius: 20px;
    gap: 10px;
    text-align: center;

}
.pill-pending{
    color:#FF7500  !important;
    background-color: #FF75004D!important;
}

.pill-completed{
    color: #057A55!important;
    background-color: #057A554D !important;
    
}

.pill-in-progress{
    color: #1C64F2!important;
    background-color:#1C64F24D !important;
    
}
.pill-closed{
    color:#6B7280 !important;
    background-color:#6B72804D !important;
    
}

