.add-new-device-input-info {
    font-size: 12px;
    margin-top: 5px;
}

.add-new-device-proof-info {
    font-size: 12px;
    margin: -10px 0 20px;
}

.add-new-device-radio-field input[type="radio"] {
    margin-left: 20px;
}

.form-field.add-new-device-proof-field {
    margin-top: -10px;
}

.form-field.add-new-device-proof-field label {
    font-size: 13px;
}

.datepicker-css {
    /* margin: 20px 0; */
    width: 100%;
    height: 50px;
    border: 1px solid #ddd;
    border-radius: 2px;
    font-size: 16px;
    padding: 0 5%;
}

.form-input {
    margin-top: 20px;
}



