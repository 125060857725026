.single-warranty-details {
    font-size: 0.9em;
}
.single-warranty-details-item {
    margin-top: 10px;
}
.single-warranty-details-label {
    font-weight: 500;
}
.single-warranty-header {
    font-size: 1.4em;
}

.warranty-out-of-warranty-flag {
    margin-top: 5px;
    padding: 5px 8px;
    background: #cc0022;
    color: white;
    display: inline-block;
    font-size: 0.7em;
    border-radius: 5px;
}

.single-warranty-stepper-icon-container {
    background-color: #ccc;
    z-index: 1;
    color: #fff;
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}
.single-warranty-step-label .MuiStepLabel-label.MuiStepLabel-completed {
    font-weight: normal;
}
.single-warranty-step-content .MuiTypography-body1 {
    font-size: 14px;
}