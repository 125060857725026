@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700);
html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

/* Clearfix */
.clearfix::after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

/* Everything box-sizing */
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

/* Some reset CSS  */
textarea {
  font-family: inherit;
}

.auth-container {
    height: 100%;
}
.auth-container-input-side {
    width: 600px;
    height: 100%;
    float: right;
    position: relative;
    overflow: auto;
}
.auth-container-img-side {
    width: calc(100% - 600px);
    height: 100%;
    float: left;
}
.auth-container-img-side .centredLogoBox {
    width: 200px;
    height: 100px;
    background-color: white;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    opacity:.7;
    margin: 0 auto;
    border-radius: 30px;
}

.auth-container-img-side .centredLogoBox .centredLogoIcon {
    width: 150px;
    display: block;
    position: relative;
    top: 49%;
    transform: translateY(-50%);
    margin: 0 auto;
}
.auth-input-side-stuff-holder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
}
.auth-input-logo-over-form {
    margin: 0 auto 35px;
    display: block;
    width: 100px;
}
@media only screen and (max-height: 460px) {
    .auth-input-side-stuff-holder {
        position: inherit;
        top: unset;
        left: unset;
        transform: none;
        margin: 0.5em auto;
    }
}
@media only screen and (max-width: 1050px) {
    .auth-container-input-side {
        width: 100%;
        float: none;
        z-index: 3;
    }
    .auth-container-img-side {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        float: none;
        z-index: 2;
    }
    .auth-container-img-side .centredLogoBox {
        display: none;
        visibility: hidden;
    }
    .auth-input-side-stuff-holder {
        background: white;
        padding: 10px 40px 20px;
        width: 380px;
        max-width: 100%;
    }
}
@media only screen and (max-width: 320px) {
    .auth-input-side-stuff-holder {
        padding: 10px 20px 20px;
    }
}

.auth-form-field {
    margin-bottom: 20px;
}
.auth-form-field label {
    display: block;
    font-family: Roboto,sans-serif;
    margin: 0 0 5px;
    font-size: 15px;
    line-height: 20px;
    font-style: normal;
    font-weight: 400;
}
.auth-form-field input {
    display: block;
    width: 100%;
    height: 50px;
    background: none;
    border: 1px solid #ddd;
    font-family: Roboto,sans-serif;
    padding: 0 12px;
    border-radius: 3px;
    font-size: 14px;
    font-style: normal;
}
@supports (-webkit-overflow-scrolling: touch) {
    /* CSS specific to iOS devices */
    .auth-form-field input {
        font-size: 16px;
    }
}
.auth-form-submit {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
    line-height: 1.5;
    border: none;
    padding: 9px 28px;
    border-radius: 40px;
    font-size: 14px;
    letter-spacing: 0;
    outline: none;
    text-decoration: none;
    background: #ef3354;
    color: #fff;
    width: 100%;
}
.auth-form-submit:hover {
    opacity: 0.8;
}

/* Checkbox styling */
/* Customize the label (the container) */
.auth-checkbox-field {
    display: block;
    float: left;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    font-size: 11px;
    color: #888888;
    font-weight: 500;
    -webkit-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.auth-checkbox-field input[type=checkbox] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom checkbox */
.auth-checkbox-field .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: transparent;
    border: 1.5px solid #aaaaaa;
}

/* On mouse-over, add a grey background color */
.auth-checkbox-field:hover input[type=checkbox]~.checkmark {
    background-color: #eaeaea;
}

/* Create the checkmark/indicator (hidden when not checked) */
.auth-checkbox-field .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.auth-checkbox-field input[type=checkbox]:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.auth-checkbox-field .checkmark:after {
    left: 5px;
    top: 2px;
    width: 3px;
    height: 6px;
    border: solid #888888;
    border-width: 0 1.5px 1.5px 0;
    transform: rotate(45deg);
}

#loginForgotPassword {
    float: right;
    font-size: 11px;
    color: #888;
    font-weight: 500;
    cursor: pointer;
}
.auth-options-section {
    margin: 20px 0;
}

.auth-page-header {
    text-align: center;
    font-size: 2em;
    margin: 0.5em 0;
}
.auth-page-info {
    font-size: 0.9em;
    margin-bottom: 2em;
    text-align: center;
}

.incorrectAuthenticationText {
    color: #a94442;
    margin-bottom: 1em;
    font-size: 1em;
}
.auth-error-modal {
    position: absolute;
    top: calc(50% - 60px);
    left: calc(50% - 200px);
    right: calc(50% - 200px);
    bottom: calc(50% - 60px);
    background: #feeff0;
    border: 1px solid #e81b37;
    padding: 20px 30px 20px 50px;
    outline: none;
}
.auth-modal-close {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    outline: none;
}
.auth-error-modal .auth-modal-close {
    color: #e81b37;
}
.auth-error-icon {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 18px;
    color: #e81b37;
}
.auth-error-main-text {
    font-weight: bold;
    margin-bottom: 1em;
    font-size: 1em;
}
.auth-error-sub-text {
    font-size: 0.9em;
}
.auth-extra-link-section {
    margin-top: 1.2em;
    font-size: 0.8em;
}

.fullScreenLoadingPage {
    width: 100%;
    height: 100%;
    position: relative; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
    animation: fadein 3s;
}
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */

/* Internet Explorer */

/* Opera < 12.1 */

.fullScreenLoadingPage img {
    display: block;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 250px;
}
.fitInBoxLoadingPage {
    text-align: center; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
    animation: fadein 3s;
}
.fitInBoxLoadingPage img {
    width: 50%;
    max-width: 250px;
}

.NotFound {
    padding-top: 100px;
    text-align: center;
}
.my-devices-active-container {
    width: 100%;
    margin-bottom: 40px;
}
.my-devices-add-new {
    color: white;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    background-color: #a94442;
    padding: 5px 10px;
    border: none;
    outline: none;
}
.my-devices-add-new-button-top {
    float: right;
}
.my-devices-add-new-main {
    margin-top: 10px;
}
.my-devices-add-new:hover {
    color: #aaaaaa;
}
.my-devices-add-new-icon {
    font-size: 14px;
    display: inline-block;
    margin-right: 5px;
    padding-top: 4px;
}
.my-devices-add-new-text {
    font-size: 13px;
    display: inline-block;
}
.my-devices-active-header {
    width: 100%;
    margin: 0;
    background-color: #a94442;
    color: white;
    font-size: 1.2em;
    font-weight: normal;
    padding: 10px 20px;
    position: relative;
}
.my-devices-active-details-container {
    display: inline-block;
    border: 1px solid #eeeeee;
    padding: 15px 20px;
}
.my-devices-active-details-full-container {
    border: 1px solid #eeeeee;
    padding: 15px 20px;
    width: 100%;
}
.my-devices-active-details-header {
    font-size: 0.8em;
    margin-bottom: 0.4em;
}
.my-devices-active-details-info {
    font-size: 1em;
}

.my-devices-active-header-icon-container {
    position: absolute;
    right: 10px;
    top: 11px;
}
.my-devices-active-header-icon {
    color: white;
    border: 0;
    outline: 0;
    padding: 0;
    background: none;
    font-size: inherit;
    margin: 0 0 0 10px;
    cursor: pointer;
}

.my-devices-warranty {
    margin-bottom: 10px;
}
.my-devices-warranty:last-of-type {
    margin-bottom: 25px;
}

.my-devices-warranty-icon {
    vertical-align: top;
    display: inline-block;
    margin-right: 5px;
}
.my-devices-warranty-date {
    font-size: 0.9em;
    color: #666666;
}
.info-device{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.device-card {
    width: 100%;
    min-height:168px;
    padding: 24px;
    border-radius: 20px;
    border: 1px solid #D2D6DC;
    margin-bottom: 20px;
    box-shadow: 0px 5px 10px 0px #00000014

}

.device-card:hover{
    border-radius: 20px;
    /* border: 1px solid #0c4aa6; */
    box-shadow: 0px 5px 10px 0px darkblue
}
.device-single-card:hover{
    border-radius: 20px;
    /* border: 1px solid #0c4aa6; */
    box-shadow: 0px 5px 10px 0px #00000014
}

.device-card-header {
  font-size: 24px;
  font-weight: 700;
}

@media screen and (max-width: 500px) {
    .device-card-header {
        font-size: 20px;
        font-weight: 700;
    }
  }

.device-card-body {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #374151;
    

}
@media screen and (max-width: 500px) {
    .device-card-body {
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        color: #374151;
        
    }
  }

.device-card-icon{
    width: 24px;
    height: 24px;
    vertical-align: text-bottom;
}
.button-icon{
    width: 10px;
    height: 10px;
    margin-right: 5px;
    vertical-align: middle;
}
.device-card-line{
    margin-top: 5px !important;
 
}

.device-add-report {
    display: block;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    height: 100%;
    width: 100%;
    margin-top: 60px;
 
}
.device-add-report-img {
    margin: 50px
}

.device-add-report-btn {
    margin: 20px
}

.tbl-row {
    height: 60px;
}

@media only screen and (max-width: 700px) {

}

.page {
    max-width: 1000px;
    width: 98%;
    height: 100%;
    margin: 0 auto;
    padding-top: 40px;
    font-family: Roboto;
}

@media only screen and (max-width: 600px) {
    .page  {
    padding: 40px 20px 75px 20px;
    width: 100%;
    }
  }

.fullpage-modal  {
    max-width: 1250px;
    width: 98%;
    height: auto;
    margin: 0 auto;
    margin-top: 40px;
    box-shadow: 5px 10px 18px #7a7979;
    border-radius: 5px;
}
.pageContent {
    margin-top: 20px;
    padding-bottom: 20px;
}
.pageHeader {
    margin-top: 0;
}

.form-field {
    margin-bottom: 20px;
}
.form-field label {
    font-family: Roboto,sans-serif;
    font-size: 15px;
    line-height: 20px;
    font-style: normal;
    font-weight: 400;
}
.form-field input[type="text"] {
    display: block;
    width: 100%;
    height: 44px;
    margin-top: 5px;
    background: none;
    border: 1px solid #ddd;
    font-family: Roboto,sans-serif;
    padding: 0 12px;
    border-radius: 8px;
    font-size: 14px;
    font-style: normal;
}
.form-field textarea {
    display: block;
    width: 100%;
    /* height: 120px; */
    background: none;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 12px;
    font-size: 14px;
    font-style: normal;
}
.form-field select {
    display: block;
    width: 100%;
    height: 50px;
    padding: 5px;
    background: none;
    border: 1px solid #ddd;
    font-family: Roboto,sans-serif;
    padding: 12px;
    border-radius: 8px;
    font-size: 14px;
    font-style: normal;
}
@supports (-webkit-overflow-scrolling: touch) {
    /* CSS specific to iOS devices */
    .form-field select, .form-field textarea, .form-field input[type="text"] {
        font-size: 16px;
    }
}
.form-submit {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
    line-height: 1.5;
    border: none;
    padding: 9px 28px;
    border-radius: 40px;
    font-size: 14px;
    letter-spacing: 0;
    outline: none;
    text-decoration: none;
    background: #ef3354;
    color: #fff;
    width: 100%;
}
.form-submit:disabled {
    opacity: 0.3;
    cursor: not-allowed;
}
.auth-form-submit:hover {
    opacity: 0.8;
}
.form-cancel {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
    line-height: 1.5;
    border: none;
    padding: 9px 28px;
    border-radius: 40px;
    font-size: 14px;
    letter-spacing: 0;
    outline: none;
    text-decoration: none;
    background: #a6a6a6;
    color: #fff;
    width: 100%;
    margin-top: 25px;
}

.table, .table th, .table td {
    border-bottom: 1px solid #cfcdcd;
    border-collapse: collapse;
}
.table {
    width: 100%;
}
.none-screen {
    text-align: center;
}
.info-modal, .confirm-modal {
    position: absolute;
    background: white;
    border: 1px solid #333333;
    outline: none;
}
.info-modal {
    top: calc(50% - 60px);
    left: calc(50% - 200px);
    right: calc(50% - 200px);
    bottom: calc(50% - 60px);
    padding: 20px 30px 20px 50px;
}
.confirm-modal {
    top: calc(50% - 70px);
    left: calc(50% - 190px);
    right: calc(50% - 190px);
    bottom: calc(50% - 70px);
    text-align: center;
    padding: 20px 0;
}
.confirm-modal-big {
    top: calc(50% - 120px);
    left: calc(50% - 220px);
    right: calc(50% - 220px);
    bottom: calc(50% - 120px);
    padding: 20px;
}
.confirm-modal-history{
    top: calc(50% - 120px);
    left: calc(50% - 220px);
    right: calc(50% - 220px);
    bottom: calc(50% - 120px);
    padding: 20px;
}
.info-modal-close, .confirm-modal-close {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    outline: none;
}

.k-confirm-modal-close {
    background: transparent;
    border: none;
    padding: 0 !important;
    margin:0 !important;
    cursor: pointer;
    font-size: 18px;
    outline: none;
}
.info-main-text {
    font-weight: bold;
    margin-bottom: 1em;
    font-size: 1em;
}
.info-sub-text {
    font-size: 0.9em;
}
.confirm-main-text {
    margin-top: 0.2em;
    font-size: 1em;
    margin-bottom: 0.4em;
}
.confirm-sub-text {
    font-size: 0.9em;
}

.button {
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
    line-height: 1.5;
    border: none;
    padding: 9px 28px;
    border-radius: 40px;
    letter-spacing: 0;
    outline: none;
    text-decoration: none;
    background: #ef3354;
    color: #fff;
    font-size: 11px;
}

.approveDeclineSet {
    margin-top: 20px;
}
.approveDeclineSet button {
    border: none;
    color: white;
    padding: 10px 20px;
    margin-right: 15px;
    cursor: pointer;
    box-shadow: 2px 2px 1px 0 rgba(0,0,0,0.3);
    font-family: inherit;
    border-radius: 20px;
    outline: none;
}
.approveDeclineSet button:hover {
    opacity: 0.7;
}
.approve {
    background: #00b200;
}
.decline {
    background: #f64e4c;
}
button.neutral {
    background: #eeeeee;
    color: black;

}

.input-modal {
    position: absolute;
    top: 10%;
    left: calc(50% - 200px);
    width: auto;
    min-width: 30%;


    background-color: white;
    border: 1px solid lightgrey;
    /* padding: 20px; */
    box-shadow: 5px 10px 18px #7a7979;
    border-radius: 5px;
}
.required span:before {
    content: "*";
    color: red;
  }


  .k-btn {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 8px;
    -webkit-user-select: none;
    user-select: none;
    height: 44px;

  }
  @media only screen and (max-width: 600px) {
    .k-btn-responsive  {
       margin-top: 10px;
      width: 100%;
    }
  }

  .k-btn-primary {
    color: #ffffff;
    background-color: #23315F;
    border-color: #23315F;
  }
  .k-btn-outline-primary {
    /* background-color: #ffffff; */
    opacity: 1;
    color: #23315F;
    border-color: #23315F;
  }

.k-btn-primary:focus,
.k-btn-primary.focus {
  color: #212020;
  background-color: white;
  border-color: #122b40;
}
.k-btn-outline-primary:focus,
.k-btn-outline-primary.focus {
  color: white;
  background-color: #23315F;
  border-color: #122b40;
}
.k-btn-primary:hover {
    color: #413e3e;
    background-color: white;
    border-color: #204d74;
  }
  .k-btn-outline-primary:hover {
    color: white;
    background-color: #23315F;
    border-color: #122b40;
  }

  .k-btn-primary-full {
    color: #ffffff;
    background-color: #23315F;
    border-color: #23315F;
    width: 100%;
  }
  .k-btn-secondary {
    color: #23315F;
    border-color: #23315F;
  }
  .k-btn-secondary-full {
    color: #23315F;
    border-color: #23315F;
    width: 100%;
    margin-top: 10px;
  }

  .k-status {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 8px;
    padding: 5px;
    font-size: 12px;
  }
  
  .k-status-ok {
    background-color: #c2efe0;
    color: #31C48D4D;


  }
  .k-status-pending {
    color: #7939004d;
    background-color: #fbc392;

  }
  
 input[type="file"] {
    display: none;
 }
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    cursor: pointer;
    margin-top: 10px;
    background-color: #4B5563;
    color: white;
    padding: 10px 10px;
    border-radius: 8px;
    font-size: 14px;
    
}

.form-input {
    margin-top: 20px;
}

.no-margin {
    margin-top: 0 !important ;
    margin-left: 0 !important;
    margin-right: 0 !important;
    
}

td,
th {
  padding: 0;
  vertical-align: middle
}

.set-warranty-modal {
    position: absolute;
    background: white;
    border: 1px solid #333333;
    outline: none;
    top: 2%;
    left: calc(50% - 500px);
    right: calc(50% - 500px);
    bottom: 2%;
    overflow: auto;
}
.page.add-new-warranty-page {
    max-width: 800px;
    padding-top: 20px;
}
.add-new-warranty-page .pageHeader{
    text-align: center;
}
.add-new-warranty-checkbox-container {
    position: relative;
    /* z-index: 1; */
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    line-height: 20px;
    font-style: normal;
    margin: 0 0 20px;
    padding: 0 0 0 30px;
    width: 50%;
}
.add-new-warranty-checkbox-container-full-width {
    width: 100%;
}
.add-new-warranty-checkbox-container input[type=checkbox] {
    box-sizing: border-box;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    /* opacity: 0; */
}
.add-new-warranty-checkbox-text::before {
    width: 20px;
    height: 20px;
    line-height: 18px;
    background: #ddd;
    border: 1px solid #a9a9a9;
    display: block;
    content: "";
    font-size: 13px;
    color: #333;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
}
.add-new-warranty-checkbox-container input[type=checkbox]:checked + .add-new-warranty-checkbox-text::before {
    content: "\2714";
}
.add-new-warranty-special-input-header {
    font-weight: 500;
    font-size: 1em;
    margin-bottom: 0.6em;
}

@media only screen and (max-width: 1050px) {
    .set-warranty-modal {
        left: 2%;
        right: 2%;
    }
}

.tab-list {
    border-bottom: 1px solid #ccc;
    padding-left: 0;
  }
  
  .tab-list-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
    cursor: pointer;
  }
  
  .tab-list-active {
    background-color: white;
    /* border: solid #ccc; */
    border-width: 1px 1px 0 1px;
    font-weight: 600;
    border-bottom: 4px solid black;
  }
.active-status{
    color: rgb(71, 227, 71);
    font-weight: 600;
}
.in-due-date, .in-stock{
    background-color: white;
}
.over-due-date, .out-stock{
    background-color: rgb(246, 239, 239);
}
.customer{
    background-color: white;
}
#due-table-datepicker{
    width: 100px;
    text-align: center;
}

.tickets-row{
height: 62px;
padding-left: 12px !important;
/* border: 0px, 0px, 1px, 0px; */
grid-gap: 20px;
gap: 20px;

}

.MuiTableCell-head {
background-color: #F4F5F7;
}

.status-pill{
    /* width: 50%; */
    /* height: 10px; */
    padding: 5px !important;
    border-radius: 20px;
    grid-gap: 10px;
    gap: 10px;
    text-align: center;

}
.pill-pending{
    color:#FF7500  !important;
    background-color: #FF75004D!important;
}

.pill-completed{
    color: #057A55!important;
    background-color: #057A554D !important;
    
}

.pill-in-progress{
    color: #1C64F2!important;
    background-color:#1C64F24D !important;
    
}
.pill-closed{
    color:#6B7280 !important;
    background-color:#6B72804D !important;
    
}


.add-new-device-input-info {
    font-size: 12px;
    margin-top: 5px;
}

.add-new-device-proof-info {
    font-size: 12px;
    margin: -10px 0 20px;
}

.add-new-device-radio-field input[type="radio"] {
    margin-left: 20px;
}

.form-field.add-new-device-proof-field {
    margin-top: -10px;
}

.form-field.add-new-device-proof-field label {
    font-size: 13px;
}

.datepicker-css {
    /* margin: 20px 0; */
    width: 100%;
    height: 50px;
    border: 1px solid #ddd;
    border-radius: 2px;
    font-size: 16px;
    padding: 0 5%;
}

.form-input {
    margin-top: 20px;
}




.half-page-image-right-container {
    height: 100%;
}
.half-page-image-right-content-side {
    width: 600px;
    height: 100%;
    float: left;
    position: relative;
    overflow: auto;
    padding: 20px 60px;
}
.half-page-image-right-img-side {
    width: calc(100% - 600px);
    height: 100%;
    float: right;
}
.half-page-image-right-content-holder {
    background: white;
    padding: 20px 60px;
}
@media only screen and (max-width: 1050px) {
    .half-page-image-right-content-side {
        width: 100%;
        float: none;
        z-index: 3;
    }
    .half-page-image-right-img-side {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        float: none;
        z-index: 2;
    }
}
@media only screen and (max-width: 450px) {
    .half-page-image-right-content-holder {
        padding: 0;
    }
}

.fullPageContainer {
    height: 100vh;
    width: 100%;
    position: relative;
    /*padding-bottom: 35px;*/
    overflow: auto;
}
.fullPageChildrenHolder {
    width: 100%;
    height: calc(100% - 50px);
}
.fullPageContainer .pageFooterContainer {
    position: absolute;
    bottom: 8px;
    right: 0;
    left: 0;
    margin: 0 auto;
    text-align: center;
    color: #333;
    font-size: 12px;
}
.fullPageContainer .pageFooterContainer .pageFooterLink {
    color: #52b4f1;
}
.NavbarAboveContainer {
    height: 50px;
    width: 100%;
    outline: none;
}
.NavbarContainer {
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
}
.NavbarLogo {
    height: 16px;
    display: inline-block;
    margin-top: 14px;
    margin-right: 30px;
}
.NavbarDropdown {
    position: absolute;
    right: 0px;
    top: 20px;
    font-size: 12px;
    line-height: 20px;
    color: #52B4F1;
    cursor: pointer;
}
.NavbarDropdownIcon {
    margin-top: -2px;
    margin-left: 3px;
    color: #333;
}
.NavbarUserIcon {
    position: absolute;
    height: 40px;
    right: 0px;
    top: 10px;
    border-radius: 50%;
}
.NavbarMenuContainer {
    position: absolute;
    right: 0;
    top: 45px;
    width: 100px;
    background-color: #ffffff;
    color: #222222;
    border-radius: 3px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 3;
}
/* Note that NavbarMenuItem can be either a link or a div  */
.NavbarMenuItem {
    border-bottom: 1px solid #dedede;
    color: #777777;
    font-size: 12px;
    font-weight: 500;
    padding: 7px 10px 5px 10px;
    cursor: pointer;
    width: 100%;
    display: block;
}
.NavbarMenuItem:hover {
    background: #dddddd;
    text-decoration: none;
    color: #777777;
}
.NavbarLinkArea {
    display: inline-block;
    vertical-align: middle;
    margin-top: 10px;
    padding-left: 10px;
}
.NavbarLink {
    margin: 0 0 0 60px;
    display: inline-block;
    position: relative;
    color: #999;
    font-size: 14px;
    font-weight: normal;
    text-decoration: none;
}
.NavbarLinkActive, .NavbarLink:hover {
    text-decoration: none;
    color: #333;
    font-weight: bold;
}

@media only screen and (max-width: 1000px)  {
    .NavbarContainer {
        width: 94%;
    }
}

@media only screen and (max-width: 850px) {
    .NavbarContainer {
        text-align: center;
        z-index: 3; /* So logo appears in tablet view in half page view */
    }
    .NavbarLink {
        margin: 0 30px;
        display: block;
        color: #333333;
        font-size: 15px;
        line-height: 40px;
        height: 40px;
    }
    .NavbarLinkActive, .NavbarLink:hover {
        color: #333333;
        font-weight: bold;
    }
    .NavbarLogo {
        margin-right: 0;
    }
    .NavbarMobileMenuHeader {
        height: 60px;
        text-align: center;
        border-bottom: 1px solid #d6d6d6;
        margin-bottom: 10px;
        outline: none;
    }
    .NavbarLogoInHeader {
        display: inline-block;
        height: 29px;
        margin-top: 14px;
    }

    /* Burger menu only exists below 850px */
    /* Position and sizing of burger button */
    .bm-burger-button {
        position: absolute;
        width: 27px;
        height: 25px;
        left: 3%;
        top: 18px;
    }

    /* Color/shape of burger icon bars */
    .bm-burger-bars {
        background: #364260;
    }

    /* Color/shape of close button cross */
    .bm-cross {
        background: #bdc3c7;
    }

    /* General sidebar styles */
    .bm-menu {
        background: #ffffff;
        padding: 0;
        font-size: 1.15em;
    }

    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
        fill: #373a47;
    }

    /* Wrapper for item list */
    .bm-item-list {
        color: #333333;
    }

    /* Individual item */
    .bm-item {
        display: inline-block;
    }

    /* Styling of overlay */
    .bm-overlay {
        background: rgba(0, 0, 0, 0.3);
    }
}

.page.add-new-warranty-page {
    max-width: 800px;
    /* padding: 16px; */

}
.add-new-warranty-checkbox-container {
    position: relative;
    /* z-index: 1; */
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    line-height: 20px;
    font-style: normal;
    margin: 0 0 20px;
    padding: 0 0 0 30px;
    width: 50%;
}
.add-new-warranty-checkbox-container-full-width {
    width: 100%;
}
.add-new-warranty-checkbox-container input[type=checkbox] {
    box-sizing: border-box;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    /* opacity: 0; */
}
.add-new-warranty-checkbox-text::before {
    width: 20px;
    height: 20px;
    line-height: 18px;
    background: #ddd;
    border: 1px solid #a9a9a9;
    display: block;
    content: "";
    font-size: 13px;
    color: #333;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
}
.add-new-warranty-checkbox-container input[type=checkbox]:checked + .add-new-warranty-checkbox-text::before {
    content: "\2714";
}
.add-new-warranty-special-input-header {
    font-weight: 500;
    font-size: 1em;
    margin-bottom: 0.6em;
}

.single-warranty-details {
    font-size: 0.9em;
}
.single-warranty-details-item {
    margin-top: 10px;
}
.single-warranty-details-label {
    font-weight: 500;
}
.single-warranty-header {
    font-size: 1.4em;
}

.warranty-out-of-warranty-flag {
    margin-top: 5px;
    padding: 5px 8px;
    background: #cc0022;
    color: white;
    display: inline-block;
    font-size: 0.7em;
    border-radius: 5px;
}

.single-warranty-stepper-icon-container {
    background-color: #ccc;
    z-index: 1;
    color: #fff;
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}
.single-warranty-step-label .MuiStepLabel-label.MuiStepLabel-completed {
    font-weight: normal;
}
.single-warranty-step-content .MuiTypography-body1 {
    font-size: 14px;
}

