.auth-container {
    height: 100%;
}
.auth-container-input-side {
    width: 600px;
    height: 100%;
    float: right;
    position: relative;
    overflow: auto;
}
.auth-container-img-side {
    width: calc(100% - 600px);
    height: 100%;
    float: left;
}
.auth-container-img-side .centredLogoBox {
    width: 200px;
    height: 100px;
    background-color: white;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    opacity:.7;
    margin: 0 auto;
    border-radius: 30px;
}

.auth-container-img-side .centredLogoBox .centredLogoIcon {
    width: 150px;
    display: block;
    position: relative;
    top: 49%;
    transform: translateY(-50%);
    margin: 0 auto;
}
.auth-input-side-stuff-holder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
}
.auth-input-logo-over-form {
    margin: 0 auto 35px;
    display: block;
    width: 100px;
}
@media only screen and (max-height: 460px) {
    .auth-input-side-stuff-holder {
        position: inherit;
        top: unset;
        left: unset;
        transform: none;
        margin: 0.5em auto;
    }
}
@media only screen and (max-width: 1050px) {
    .auth-container-input-side {
        width: 100%;
        float: none;
        z-index: 3;
    }
    .auth-container-img-side {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        float: none;
        z-index: 2;
    }
    .auth-container-img-side .centredLogoBox {
        display: none;
        visibility: hidden;
    }
    .auth-input-side-stuff-holder {
        background: white;
        padding: 10px 40px 20px;
        width: 380px;
        max-width: 100%;
    }
}
@media only screen and (max-width: 320px) {
    .auth-input-side-stuff-holder {
        padding: 10px 20px 20px;
    }
}

.auth-form-field {
    margin-bottom: 20px;
}
.auth-form-field label {
    display: block;
    font-family: Roboto,sans-serif;
    margin: 0 0 5px;
    font-size: 15px;
    line-height: 20px;
    font-style: normal;
    font-weight: 400;
}
.auth-form-field input {
    display: block;
    width: 100%;
    height: 50px;
    background: none;
    border: 1px solid #ddd;
    font-family: Roboto,sans-serif;
    padding: 0 12px;
    border-radius: 3px;
    font-size: 14px;
    font-style: normal;
}
@supports (-webkit-overflow-scrolling: touch) {
    /* CSS specific to iOS devices */
    .auth-form-field input {
        font-size: 16px;
    }
}
.auth-form-submit {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    cursor: pointer;
    line-height: 1.5;
    border: none;
    padding: 9px 28px;
    border-radius: 40px;
    font-size: 14px;
    letter-spacing: 0;
    outline: none;
    text-decoration: none;
    background: #ef3354;
    color: #fff;
    width: 100%;
}
.auth-form-submit:hover {
    opacity: 0.8;
}

/* Checkbox styling */
/* Customize the label (the container) */
.auth-checkbox-field {
    display: block;
    float: left;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    font-size: 11px;
    color: #888888;
    font-weight: 500;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.auth-checkbox-field input[type=checkbox] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom checkbox */
.auth-checkbox-field .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: transparent;
    border: 1.5px solid #aaaaaa;
}

/* On mouse-over, add a grey background color */
.auth-checkbox-field:hover input[type=checkbox]~.checkmark {
    background-color: #eaeaea;
}

/* Create the checkmark/indicator (hidden when not checked) */
.auth-checkbox-field .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.auth-checkbox-field input[type=checkbox]:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.auth-checkbox-field .checkmark:after {
    left: 5px;
    top: 2px;
    width: 3px;
    height: 6px;
    border: solid #888888;
    border-width: 0 1.5px 1.5px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

#loginForgotPassword {
    float: right;
    font-size: 11px;
    color: #888;
    font-weight: 500;
    cursor: pointer;
}
.auth-options-section {
    margin: 20px 0;
}

.auth-page-header {
    text-align: center;
    font-size: 2em;
    margin: 0.5em 0;
}
.auth-page-info {
    font-size: 0.9em;
    margin-bottom: 2em;
    text-align: center;
}

.incorrectAuthenticationText {
    color: #a94442;
    margin-bottom: 1em;
    font-size: 1em;
}
.auth-error-modal {
    position: absolute;
    top: calc(50% - 60px);
    left: calc(50% - 200px);
    right: calc(50% - 200px);
    bottom: calc(50% - 60px);
    background: #feeff0;
    border: 1px solid #e81b37;
    padding: 20px 30px 20px 50px;
    outline: none;
}
.auth-modal-close {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    outline: none;
}
.auth-error-modal .auth-modal-close {
    color: #e81b37;
}
.auth-error-icon {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 18px;
    color: #e81b37;
}
.auth-error-main-text {
    font-weight: bold;
    margin-bottom: 1em;
    font-size: 1em;
}
.auth-error-sub-text {
    font-size: 0.9em;
}
.auth-extra-link-section {
    margin-top: 1.2em;
    font-size: 0.8em;
}
